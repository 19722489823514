@font-face {
  src: local("Gilroy"), url("../../../public/fonts/Gilroy-UltraLight.woff2") format("woff2");
  font-family: "Gilroy";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Gilroy"), url("../../../public/fonts/Gilroy-Regular.woff2") format("woff2");
  font-family: "Gilroy";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Gilroy"), url("../../../public/fonts/Gilroy-Medium.woff2") format("woff2");
  font-family: "Gilroy";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Gilroy"), url("../../../public/fonts/Gilroy-Semibold.woff2") format("woff2");
  font-family: "Gilroy";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Gilroy"), url("../../../public/fonts/Gilroy-Bold.woff2") format("woff2");
  font-family: "Gilroy";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("SF Pro"), url("../../../public/fonts/SFPro-Medium.woff2") format("woff2");
  font-family: "SF Pro";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("SF Pro"), url("../../../public/fonts/SFPro-Regular.woff2") format("woff2");
  font-family: "SF Pro";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Futura Now"), url("../../../public/fonts/FuturaNow-Bold.woff2") format("woff2");
  font-family: "Futura Now";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Gibson"), url("../../../public/fonts/Gibson-Regular.woff2") format("woff2");
  font-family: "Gibson";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: local("Noto Sans"), url("../../../public/fonts/NotoSans-Regular.woff2") format("woff2");
  font-family: "Noto Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
