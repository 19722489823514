@import "../../../../assets/styles/functions/fluid";
@import "../../../../assets/styles/functions/em";

.widget-copy {
  display: flex;
  align-items: center;
  border-radius: 3rem;
  padding: 2.2rem 2.4rem;
  box-shadow: 8px 12px 50px rgba(185, 197, 222, 0.2);
  background-color: var(--clr-default-100);

  &__icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    border-radius: 1.4rem;
    width: 5.4rem;
    height: 5.4rem;
    background-color: var(--clr-default-400);

    img {
      width: 2.4rem;
      height: 2.4rem;
      object-fit: contain;
    }
  }

  &__content {
    flex-grow: 1;
    padding-left: 1.4rem;
  }

  &__title {
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--clr-text-400);
  }

  &__text {
    font-weight: 500;
    font-size: fluid(1.6rem, 1.4rem);
    word-break: break-all;
    text-align: left;
    color: var(--clr-text-600);
  }

  @media (max-width: em(576)) {
    padding: 2rem;
  }
}
