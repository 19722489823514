.toast {
  position: relative;

  &__target {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  &__content {
    position: absolute;
    right: -0.2rem;
    top: 50%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.7rem;
    padding: 1rem 1.8rem;
    width: 100%;
    min-height: 4.2rem;
    box-shadow: 0px 10px 15px rgba(179, 188, 202, 0.3);
    font-weight: 400;
    font-size: 1.6rem;
    color: var(--clr-text-900);
    background-color: var(--clr-default-100);
    opacity: 0;
    visibility: hidden;
    transform: translate(20%, -50%);
    transition: opacity var(--transition), visibility var(--transition), transform var(--transition);
    pointer-events: none;

    & > svg {
      margin-left: 1rem;
      width: 1.8rem;
      height: 1.8rem;
      fill: none !important;
      object-fit: contain;
    }
  }

  &_open {
    &.toast_position_right {
      .toast__content {
        opacity: 1;
        visibility: visible;
        transform: translate(0%, -50%);
      }
    }

    &.toast_position_left {
      .toast__content {
        opacity: 1;
        visibility: visible;
        transform: translate(15%, -50%);
      }
    }
  }

  &_type {
    &_success {
      .toast__content > svg {
        stroke: var(--clr-ui-success);
      }
    }

    &_error {
      .toast__content > svg {
        stroke: var(--clr-ui-error);
      }
    }
  }

  &_position {
    &_right {
      .toast__content {
        right: -0.2rem;
        transform: translate(20%, -50%);
      }
    }

    &_left {
      .toast__content {
        left: -0.2rem;
        transform: translate(-10%, -50%);
      }
    }
  }
}
