.pagination {
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid rgba(var(--clr-primary-400-rgb) / 45%);
    border-radius: 0.9rem;
    width: 4.6rem;
    height: 4.6rem;
    font-weight: 400;
    font-size: fluid(1.6rem, 1.4rem);
    color: var(--clr-text-800);
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }

    svg {
      stroke: var(--clr-text-800);
    }

    &[disabled] {
      cursor: default;

      svg {
        stroke: var(--clr-text-300);
      }
    }

    &_active {
      color: var(--clr-text-100);
      background-color: var(--clr-primary-400);
      cursor: default;
      pointer-events: none;
    }

    &_divider {
      pointer-events: none;
    }

    &_prev,
    &_next {
      border: none;
    }

    &_prev {
      margin-right: 1rem;
    }

    &_next {
      margin-left: 0rem;
    }

    @media (hover) {
      transition: background-color var(--transition);

      &:not([disabled]) {
        &:hover {
          background-color: rgba(var(--clr-primary-400-rgb) / 20%);
          transition: background-color var(--transition);
        }
      }
    }
  }
}
