.form-checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &__field {
    position: relative;
    flex-shrink: 0;
    align-self: flex-start;
    border-radius: 0.3rem;
    transform: translateY(-0.1rem);
    transition: border-color var(--transition);
    pointer-events: none;

    & > svg {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: opacity var(--transition), visibility var(--transition);
    }
  }

  input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);

    &:checked {
      & + .form-checkbox__field {
        & > svg {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &_size {
    &_default {
      .form-checkbox__field {
        border: 0.1rem solid var(--clr-ui-border-300);
        width: 1.6rem;
        height: 1.6rem;

        & > svg {
          left: 18%;
          top: -16%;
          stroke-width: 1.2;
        }
      }

      .form-checkbox__label {
        padding-left: 1rem;
        font-weight: 400;
        font-size: 1.4rem;
        color: var(--clr-text-900);
      }
    }

    &_large {
      .form-checkbox__field {
        border: 0.2rem solid var(--clr-ui-border-300);
        width: 2rem;
        height: 2rem;

        & > svg {
          left: 0;
          top: 0;
          stroke-width: 1.5;
        }
      }

      .form-checkbox__label {
        padding-left: 1.2rem;
        font-weight: 600;
        font-size: fluid(2rem, 1.6rem);
        color: var(--clr-text-500);
      }

      input:checked + .form-checkbox__field {
        border-color: var(--clr-primary-400);
      }
    }
  }

  &_color {
    &_default {
      .form-checkbox__field {
        & > svg {
          stroke: var(--clr-primary-400);
        }
      }
    }

    &_white {
      .form-checkbox__field {
        border-color: var(--clr-default-100);

        & > svg {
          stroke: var(--clr-default-100);
        }
      }
    }
  }

  &_disabled {
    cursor: default;
  }
}
