.widget-info {
  border-radius: 3rem;
  padding: 2.2rem 4rem;
  box-shadow: 8px 12px 50px rgba(185, 197, 222, 0.2);
  background-color: var(--clr-default-100);

  &__title {
    margin-bottom: 0.6rem;
    font-weight: 500;
    font-size: fluid(1.8rem, 1.6rem);
    color: var(--clr-text-500);
  }

  &__text {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 160%;
    color: var(--clr-text-400);
  }
}
