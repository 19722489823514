// Functions
@import "./functions/fluid";
@import "./functions/em";

// Fonts
@import "./fonts";

// Normalize
@import "./normalize";

// Libs
@import "./libs/swiper";

// Variables
:root {
  // base
  --font-family: "Gilroy", sans-serif;
  --font-weight: 500;
  --transition: 0.3s;
  --content-width: 120rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // default colors
  --clr-default-100-rgb: 255, 255, 255;
  --clr-default-100: #fff;
  --clr-default-200: #f9fafe;
  --clr-default-300: #dce5fd;
  --clr-default-300-hover: #eef2fc;
  --clr-default-400: #f8f9fd;
  --clr-default-500: #e3e3e7;
  --clr-default-600: #75808f;
  --clr-default-900: #000;

  // text colors
  --clr-text-100-rgb: 255, 255, 255;
  --clr-text-100: #fff;
  --clr-text-300: #75808f;
  --clr-text-400: #63637c;
  --clr-text-500: #424d69;
  --clr-text-600: #263354;
  --clr-text-700: #193375;
  --clr-text-800: #3c3d42;
  --clr-text-900: #343435;

  // primary colors
  --clr-primary-400-rgb: 81 127 245;
  --clr-primary-400: #517ff5;
  --clr-primary-500: #2055dd;
  --clr-primary-800: #193375;

  // ui colors
  --clr-ui-scroll-track: rgba(81 127 245 / 40%);
  --clr-ui-scroll-track-light: rgba(81 127 245 / 20%);
  --clr-ui-scroll-thumb: #517ff5;
  --clr-ui-border-200: #d7dbec;
  --clr-ui-border-300: #c2c9d5;
  --clr-ui-success: #32dc62;
  --clr-ui-error: #dc4732;
  --clr-ui-shadow-100: rgb(167 173 183 / 20%);

  // Scrollbar
  --scrollbar-width: 0.5rem;
  --scrollbar-size-precentage: 50;
  --scrollbar-size: calc(var(--scrollbar-size-precentage) * 0.01rem);
}

body {
  font-size: 1.6rem;
  color: var(--clr-text-400);
  background-color: var(--clr-default-100);

  &.lock {
    overflow: hidden;
  }
}

#root {
  display: flex;
  min-height: 100%;
}

::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
  background-color: var(--clr-ui-scroll-track);
}

::-webkit-scrollbar-track {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
  background-color: var(--clr-ui-scroll-track);
}

::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-size);
  width: calc(var(--scrollbar-size) + 0.1rem);
  height: calc(var(--scrollbar-size) + 0.1rem);
  background-color: var(--clr-ui-scroll-thumb);
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.circles {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    background-image: linear-gradient(
      88deg,
      rgba(255 255 255 / 9%) -8.13%,
      rgba(255 255 255 / 45%) 90.74%
    );
  }

  &::before {
    left: 16%;
    top: 2%;
    width: 11rem;
    height: 11rem;
  }

  &::after {
    left: 21%;
    top: 57%;
    width: 11rem;
    height: 11rem;
  }
}

.collapse {
  overflow: hidden;
  box-sizing: content-box;
  transition: height var(--transition), transform var(--transition), padding var(--transition);
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  80%,
  100% {
    transform: translate3d(0, 0, 0);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  40%,
  43% {
    transform: translate3d(0, -30px, 0);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  70% {
    transform: translate3d(0, -15px, 0);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.custom-snackbar.notistack-SnackbarContainer {
  top: 9rem;
}
.custom-snackbar .notistack-Snackbar {
  min-width: auto;
}
