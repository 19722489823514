.proxy-list {
  &__item {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 0.1rem solid var(--clr-ui-border-200);
    border-radius: 3rem;
    padding: 2rem 2.5rem;
    background-color: var(--clr-default-100);

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &-header {
      margin-bottom: 1.5rem;
    }

    &-name {
      display: flex;
      align-items: center;
    }

    &-image {
      flex-shrink: 0;
      margin-right: 1rem;
      width: 3rem;
      height: 3rem;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-label {
      font-weight: 600;
      font-size: 1.4rem;
      color: var(--clr-text-900);
    }

    &-body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
      grid-gap: 2rem;
    }

    // &-info {
    //   // &:not(:last-child) {
    //   //   margin-right: 2rem;
    //   // }
    // }

    &-count {
      margin-bottom: 0.4rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: var(--clr-text-400);
    }

    &-price {
      font-weight: 500;
      font-size: 1.4rem;
      color: var(--clr-text-900);
      white-space: nowrap;
    }

    &-footer {
      margin-top: auto;
      padding-top: 2rem;

      .button {
        padding: 0.5rem 2.5rem;
        min-height: 3.6rem;
      }
    }
  }
}
