.swiper {
  position: relative;
  z-index: 1;
  list-style: none;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  transition-property: transform;
}

.swiper-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-pointer-events {
  touch-action: pan-y;
}

.swiper-slide {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-autoheight {
  &,
  .swiper-slide {
    height: auto;
  }

  .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height;
  }
}

.swiper-backface-hidden .swiper-slide {
  backface-visibility: hidden;
  transform: translateZ(0);
}
