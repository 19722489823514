@import "../../../assets/styles/functions/fluid";
@import "../../../assets/styles/functions/em";

.reviews-list {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 4.5rem;

  @media (max-width: em(767)) {
    grid-template-columns: repeat(1, 1fr);
  }
}
