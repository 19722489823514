@import "../../../assets/styles/functions/fluid";
@import "../../../assets/styles/functions/em";

.note {
  & .note__head {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    & > img {
      flex-shrink: 0;
      margin-right: 1rem;
      width: 1.8rem;
      height: 1.8rem;
      object-fit: contain;

      @media (max-width: em(576)) {
        display: none;
      }
    }
  }

  p {
    padding-left: 2.8rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 160%;
    color: var(--clr-text-400);

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: em(576)) {
      padding-left: 0;
    }
  }
}
