@import "../../../assets/styles/functions/fluid";
@import "../../../assets/styles/functions/em";

.working-hours {
  &__title {
    margin-bottom: 1.8rem;
    font-weight: 500;
    font-size: fluid(1.6rem, 1.4rem);
    color: var(--clr-text-900);
  }

  &__text {
    font-weight: 400;
    font-size: fluid(1.6rem, 1.4rem);
    line-height: 150%;
    color: var(--clr-text-400);
  }
}
