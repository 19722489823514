.form-radio-group {
  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &-field {
      position: relative;
      display: block;
      flex-shrink: 0;
      align-self: flex-start;
      border: 0.1rem solid var(--clr-primary-400);
      border-radius: 50%;
      width: 1.4rem;
      height: 1.4rem;
      min-height: 1.4rem;
      pointer-events: none;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        // width: 0.9rem;
        // height: 0.9rem;
        width: 80%;
        height: 80%;
        background-color: var(--clr-primary-400);
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);
        transition: opacity var(--transition), visibility var(--transition);
      }
    }

    &-label {
      padding-left: 1rem;
      font-weight: 400;
      font-size: 1.4rem;
      color: var(--clr-text-900);
    }

    input {
      position: absolute;
      overflow: hidden;
      width: 1px;
      height: 1px;
      clip: rect(0 0 0 0);

      &:checked {
        & + .form-radio-group__item-field::before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
