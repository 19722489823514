@import "../../../../assets/styles/functions/fluid";
@import "../../../../assets/styles/functions/em";

.widget-table {
  position: relative;
  border-radius: 3rem;
  padding: 3.5rem 4.6rem;
  box-shadow: 8px 12px 50px rgba(185, 197, 222, 0.2);
  background-color: var(--clr-default-100);

  &::before {
    content: "";
    position: absolute;
    top: 1.7rem;
    bottom: 1.7rem;
    z-index: 0;
    background-color: var(--clr-default-400);

    @media (max-width: em(576)) {
      display: none;
    }
  }

  &__row {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    overflow: hidden;
    word-wrap: break-word;

    &_heading {
      .widget-table {
        &__key,
        &__value {
          // width: 100%;
          padding-bottom: 1.7rem;
          font-weight: 500;
        }
      }
    }

    @media (max-width: em(576)) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__key {
    display: flex;
    padding: 1rem 0;
    width: 50%;
    font-weight: 500;
    font-size: 1.4rem;
    text-align: left;
    color: var(--clr-text-400);

    img {
      margin-right: 1.2rem;
      width: 1.8rem;
      height: 1.8rem;
      object-fit: contain;
    }

    @media (max-width: em(576)) {
      position: relative;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 4.6rem;
      line-height: 160%;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: -1;
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        background-color: var(--clr-default-400);
        transform: translate(-50%, -50%);
      }
    }
  }

  &__value {
    align-self: flex-start;
    padding: 1rem 0;
    width: 50%;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 160%;

    @media (max-width: em(576)) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 4.6rem;
      text-align: center;
    }
  }

  &_size {
    &_small {
      &.widget-table::before {
        width: calc(50% - 1.7rem);
      }

      .widget-table {
        &__key {
          padding-right: 2rem;

          @media (max-width: em(576)) {
            padding-right: 0;
          }
        }

        &__value {
          padding-left: 2rem;

          @media (max-width: em(576)) {
            padding-left: 0;
          }
        }
      }
    }

    &_default {
      &.widget-table::before {
        width: calc(50% - 3rem);
      }

      .widget-table {
        &__key {
          padding-right: 4rem;

          @media (max-width: em(576)) {
            padding-right: 0;
          }
        }

        &__value {
          padding-left: 4rem;

          @media (max-width: em(576)) {
            padding-left: 0;
          }
        }
      }
    }
  }

  &_patch {
    &_left {
      &.widget-table {
        &::before {
          border-top-left-radius: 2rem;
          border-bottom-left-radius: 2rem;
        }

        &_size_small {
          &::before {
            left: 1.7rem;
          }
        }

        &_size_default {
          &::before {
            left: 3rem;
          }
        }
      }
    }

    &_right {
      &.widget-table {
        &::before {
          border-top-right-radius: 2rem;
          border-bottom-right-radius: 2rem;
        }

        &_size_small {
          &::before {
            right: 1.7rem;
          }
        }

        &_size_default {
          &::before {
            right: 3rem;
          }
        }
      }
    }
  }

  @media (max-width: em(576)) {
    padding: 2rem;
  }
}
