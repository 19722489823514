@import "../../../../assets/styles/functions/fluid";
@import "../../../../assets/styles/functions/em";

.reviews-slider {
  overflow: visible;

  .swiper-slide-active > .reviews-card {
    border-color: transparent;
    box-shadow: 0 15px 25px rgba(179 184 202 / 23%);
  }

  .swiper-slide {
    height: auto;
  }

  & + .proxy-reviews__link,
  & + .home-reviews__link {
    margin-top: 4rem;
  }
}
