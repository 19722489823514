@import "../../../assets/styles/functions/fluid";
@import "../../../assets/styles/functions/em";

.form-select {
  position: relative;

  &__current {
    overflow: hidden;
    width: 100%;
    font-weight: 700;
    font-size: 1.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--clr-text-900);
  }

  &__placeholder {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--clr-default-600);
  }

  &__arrow {
    position: absolute;
    right: 1.9rem;
    flex-shrink: 0;
    transition: transform var(--transition);

    & > svg {
      object-fit: contain;
    }
  }

  &__body {
    position: absolute;
    left: 0;
    top: 115%;
    z-index: 5;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transform: translateY(2rem);
    transition: visibility var(--transition), opacity var(--transition), transform var(--transition);
  }

  &__inner {
    overflow: hidden;
    border-radius: 1.2rem;
    box-shadow: 0 2px 22px rgba(95, 103, 128, 0.14);
  }

  &__list {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 13rem;
    background-color: var(--clr-default-100);
  }

  &__item {
    button {
      display: flex;
      align-items: center;
      padding: 1rem 2.1rem;
      width: 100%;
      text-align: left;
    }

    &:hover {
      background-color: var(--clr-default-300-hover);
    }

    &-text {
      font-weight: 400;
      font-size: 1.4rem;
      color: var(--clr-text-400);
    }

    &-icon {
      flex-shrink: 0;
      overflow: hidden;
      margin-right: 1rem;
      border-radius: 50%;
      width: 1.7rem;
      height: 1.7rem;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-plug {
      margin-right: 1rem;
      width: 1.7rem;
      height: 1.7rem;
    }

    &_active {
      background-color: var(--clr-default-300);
      pointer-events: none;
    }
  }

  .form-item {
    &__field {
      position: relative;
      cursor: pointer;
    }

    &__input {
      display: none;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      pointer-events: none;
    }
  }

  &.form-item_direction {
    &_horizontal {
      .form-item__actions {
        padding-right: 2.4rem;
      }

      .form-select {
        &__icon {
          flex-shrink: 0;
          overflow: hidden;
          margin-right: 1rem;
          border-radius: 50%;
          width: 1.7rem;
          height: 1.7rem;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        &__current {
          padding-right: 3rem;
          font-weight: 400;
        }

        &__arrow {
          top: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 2.4rem;
          height: 2.4rem;
          background-color: var(--clr-primary-400);
          transform: translateY(-50%);

          svg {
            padding-top: 0.2rem;
            width: 1rem;
            height: 1rem;
            stroke: var(--clr-text-100);
            object-fit: contain;
          }
        }
      }
    }

    &_vertical {
      .form-item__actions {
        padding-right: 2.4rem;
      }

      .form-select {
        &__icon {
          display: none;
        }

        &__current {
          padding-right: 1.5rem;
          font-weight: 600;

          @media (max-width: em(576)) {
            font-weight: 500;
          }
        }

        &__arrow {
          bottom: 1.8rem;
          width: 1.3rem;
          height: 1.3rem;

          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  &.form-item_variant {
    &_table {
      .form-select {
        &__icon {
          display: none;
          width: 1.5rem;
          height: 1.5rem;
        }

        &__item {
          &-icon {
            width: 1.5rem;
            height: 1.5rem;
          }

          &-plug {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &__current {
          color: var(--clr-text-100);
        }

        &__arrow {
          right: 0;
          width: 1.8rem;
          height: 1.8rem;
          background-color: var(--clr-default-100);

          svg {
            width: 0.8rem;
            stroke: var(--clr-default-600);
            object-fit: contain;
          }
        }
      }
    }
  }

  &_active {
    &.form-item_direction_horizontal {
      .form-select__arrow {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &.form-item_direction_vertical {
      .form-select__arrow {
        transform: rotate(180deg);
      }
    }

    .form-item__field {
      border-color: var(--clr-primary-400);
    }
    .form-select__body {
      opacity: 1;
      visibility: visible;
      transform: translateY(0rem);
    }
  }
}
